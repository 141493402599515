<script>
import api from '@/command/api'
import { Button } from 'ant-design-vue'
import LineCharts from './lineCharts'

export default {
  data() {
    return {
      ...api.command.getState(),
      gksj: require('@/assets/View/gk.png'),
      spsj: require('@/assets/View/sp.png'),
      onYes: '观看次数',
      dataLeft: [
        {
          name: '峰值在线人数',
          value: 211
        },
        {
          name: '观看次数',
          value: 350
        },
        {
          name: '观看人数',
          value: 2154
        },
        {
          name: '平均观看时长',
          value: '00:00:53'
        }
      ],
      dataRight: [
        {
          name: '商品点击人数',
          value: 211
        },
        {
          name: '商品点击次数',
          value: 350
        },
        {
          name: '累计推送次数',
          value: 2154
        }
      ],
      lineData: [
        { year: '10/20', value: 30 },
        {
          year: '10/21',
          value: 40
        },
        {
          year: '10/22',
          value: 30.5
        },
        {
          year: '10/23',
          value: 50
        },
        {
          year: '10/24',
          value: 40.9
        },
        {
          year: '10/25',
          value: 60
        },
        {
          year: '10/26',
          value: 70
        },
        {
          year: '10/27',
          value: 90
        },
        {
          year: '10/28',
          value: 63
        }
      ]
    }
  },
  mounted() {},
  methods: {
    renderTop() {
      return (
        <div class="wrapper">
          <div class="left-box">
            <img class="gksj" src={this.gksj} />
            <div class="left-box_one">
              {this.dataLeft.map(e => {
                return (
                  <aside>
                    <span>{e.name}</span>
                    <span>{e.value}</span>
                  </aside>
                )
              })}
            </div>
          </div>
          <div class="right-box">
            <img class="spsj" src={this.spsj} />
            <div class="left-box_one">
              {this.dataRight.map(e => {
                return (
                  <aside>
                    <span>{e.name}</span>
                    <span>{e.value}</span>
                  </aside>
                )
              })}
            </div>
          </div>
        </div>
      )
    },
    handleBtnClick(e) {
      console.log(e)
    },
    renderBroken() {
      return (
        <div class="broken-box">
          <div class="title-box">
            实时数据曲线图<span class="iconfont icon-wenhao1"></span>
          </div>
          <div class="btn-box" onClick={e => this.handleBtnClick(e)}>
            <Button
              data-btnIndex="1"
              onClick={() => (this.onYes = '观看次数')}
              class={this.onYes == '观看次数' ? 'yes' : ''}
            >
              观看次数
            </Button>
            <Button
              onClick={() => (this.onYes = '在线人数')}
              data-btnIndex="2"
              class={this.onYes == '在线人数' ? 'yes' : ''}
            >
              在线人数
            </Button>
            <Button
              onClick={() => (this.onYes = '商品点击次数')}
              data-btnIndex="3"
              class={this.onYes == '商品点击次数' ? 'yes' : ''}
            >
              商品点击次数
            </Button>
          </div>
          <div class="broken-line-box">
            <div class="text-box">
              <span></span>
              <span>{this.onYes}</span>
            </div>
            <div id="chart-box">
              <LineCharts charData={this.lineData} id="chart1" />
            </div>
          </div>
        </div>
      )
    }
  },
  render() {
    return (
      <div>
        {this.renderTop()}
        {this.renderBroken()}
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.broken-box {
  width: 100%;
  height: 582px;
  background: white;
  margin: 10px 0;
  padding: 25px 24px 10px;
  box-sizing: border-box;
  border-radius: 5px;

  .broken-line-box {
    margin-top: 30px;
    height: 420px;
    background-color: #fefefe;
    margin-left: -12px;
    padding-left: 12px;

    #chart-box {
      margin-top: 20px;
      width: 100%;
      height: 80%;
      margin-left: -50px;
    }

    .text-box {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;

        &:nth-of-type(1) {
          display: inline-block;
          width: 3px;
          height: 17px;
          background: #6467f0;
          margin-right: 10px;
        }
      }
    }
  }

  .btn-box {
    margin-top: 20px;

    .ant-btn:hover {
      background-color: #f1f1fe;
      color: #7476f1;
    }

    .ant-btn {
      width: auto;
      height: 32px;
      background-color: #f6f6f6;
      margin-right: 15px;
      border: none;
      border-radius: 20px;

      &.yes {
        background-color: #f1f1fe;
        color: #7476f1;
      }
    }
  }

  .title-box {
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #000;

    .icon-wenhao1 {
      font-size: 20px;
      color: #ccc;
      margin-left: 10px;
    }
  }
}

.wrapper {
  height: 191px;
  width: 100%;
  background: white;
  margin: 10px 0;
  padding: 25px 24px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;

  .left-box {
    width: 50%;
    height: 100%;
    padding-left: 71px;

    .left-box_one {
      width: 100%;
      height: 58px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      padding-top: 50px;
      position: relative;

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 58%;
        right: 0;
        background-color: #f2f2f2;
      }

      aside {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 35px;
        padding-right: 100px;
        margin-top: 15px;

        span {
          color: #000;

          &:nth-of-type(1) {
            font-weight: 400;
            font-size: 14px;
          }
          &:nth-of-type(2) {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }

    .gksj {
      width: 40%;
    }
  }

  .right-box {
    width: 50%;
    height: 100%;
    padding-left: 95px;

    .left-box_one {
      width: 100%;
      height: 58px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      padding-top: 50px;
      position: relative;

      aside {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 100px;
        margin-top: 15px;

        span {
          color: #000;

          &:nth-of-type(1) {
            font-weight: 400;
            font-size: 14px;
          }
          &:nth-of-type(2) {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }

    .spsj {
      width: 40%;
    }
  }
}
</style>
