<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import PushInfo from './comp/PushInfo'
import { Button, Tabs } from 'ant-design-vue'
import GoodsShelves from './comp/GoodsShelves'
import RealTime from './comp/RealTime'
import NetworkMonito from './comp/NetworkMonito'
export default {
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    const { id } = this.$route.query

    // api.command.getList.call(this, {
    //   url: '/market/farmProductRoom/page',
    //   current: 1,
    //   paramsValue: {
    //     roomId: this.roomId
    //   }
    // })
  },
  methods: {
    renderTop() {
      const data = [
        {
          name: '在线',
          value: 200
        },
        {
          name: '观看',
          value: 200
        },
        {
          name: '点击次数',
          value: 200
        },
        {
          name: '点击人数',
          value: 200
        },
        {
          name: '付款成功',
          value: 200
        }
      ]
      return (
        <div class="wrapper">
          <div class="home-top">
            <div class="box">
              <div class="img_box"></div>
              <div class="text_box">
                <span>甬农鲜福利特惠！绑卡减20最后1天</span>
                <span>开播时间: 2021/6/10 09:50 - 12:41</span>
              </div>
            </div>
            {data.map(e => {
              return (
                <div class="home-top-item">
                  <div>{e.value}</div>
                  <div>{e.name}</div>
                </div>
              )
            })}
          </div>
          <div class="home-btm">
            <div class="text-box">
              <span>直播已结束: 05:50:48</span>
            </div>
            <div class="btn-box">
              <Button class="btn-1">开始直播</Button>
              <Button class="btn-2">直播结束</Button>
              <Button class="btn-3" onClick={() => this.pushUrl()}>
                推流地址
              </Button>
              <Button class="btn-3">暂停直播</Button>
              <div class="text_box1">
                <span>推流状态</span>
                <span>未检测到推流</span>
              </div>
            </div>
          </div>
        </div>
      )
    },
    pushUrl() {
      apiTool.showDrawer({
        title: '推流信息',
        width: '680px',
        view: PushInfo,
        viewProps: {},
        success: dataSource => {
          const { data, setHidden } = dataSource
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            }
          ]
        }
      })
    },
    renderTabs() {
      // <Tabs.TabPane tab="实时数据" key="2">
      //   <RealTime />
      // </Tabs.TabPane>
      // <Tabs.TabPane tab="网络监控" key="3">
      //   <NetworkMonito />
      // </Tabs.TabPane>
      return (
        <div class="tabs_box">
          <Tabs>
            <Tabs.TabPane tab="商品上架" key="1">
              <GoodsShelves roomId={this.$route.query.id} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )
    }
  },
  render() {
    // {this.renderTop()}
    return <div>{this.renderTabs()}</div>
  }
}
</script>

<style lang="less" scoped>
.tabs_box {
  /deep/.ant-tabs-tab {
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    padding: 12px 0px;
  }
  /deep/.ant-tabs-ink-bar {
    height: 3px;
    background-color: #515a6e;
  }
}

.wrapper {
  height: 249px;
  width: 100%;
  background: white;
  margin: 10px 0;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 5px;

  .home-top {
    width: 100%;
    height: 124px;
    border-bottom: 1px solid rgb(229, 231, 233);
    display: flex;
    align-items: center;
    .box {
      display: flex;
      align-items: center;
      width: 55%;

      .text_box {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          font-family: 'PingFang SC Normal', 'PingFang SC';
          font-weight: 500;
          font-style: normal;
          color: #333;
          &:nth-of-type(1) {
            font-size: 28px;
          }
          &:nth-of-type(2) {
            font-size: 14px;
          }
        }
      }
      .img_box {
        width: 133px;
      }
    }
  }
  .home-btm {
    width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-box {
      width: 55%;
      font-family: 'PingFang SC Normal', 'PingFang SC';
      font-weight: 500;
      font-style: normal;
      color: #333;
      font-size: 28px;
    }

    .btn-box {
      //   width: 20%;
      display: flex;
      align-items: center;

      .text_box1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 20px;

        span {
          font-family: 'PingFang SC Normal', 'PingFang SC';
          font-weight: 500;
          font-style: normal;
          color: #333;

          &:nth-of-type(2) {
            font-size: 20px;
          }
          &:nth-of-type(1) {
            font-size: 14px;
          }
        }
      }

      .btn-1 {
        width: 156px;
        height: 32px;
        background-color: rgb(44, 140, 240);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(255, 255, 255);
        margin-right: 5px;
      }
      .btn-2 {
        width: 156px;
        height: 32px;
        background-color: rgb(163, 0, 20);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(255, 255, 255);
        margin-right: 5px;
      }
      .btn-3 {
        width: 84px;
        height: 32px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        font-family: 'PingFang SC Normal', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: rgb(81, 90, 110);
        margin-right: 25px;
      }
    }
  }

  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    border-left: 1px solid rgb(229, 231, 233);

    div {
      font-family: 'PingFang SC Normal', 'PingFang SC';
      font-weight: 500;
      font-style: normal;
      color: #333;
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 14px;
      }
    }
  }
}
</style>
