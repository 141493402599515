<script>
import { Table } from 'ant-design-vue'
import api from '@/command/api'
import { getAction, postAction, putAction } from '@/command/netTool'
import { copyUrl } from '@/utils/util'

export default {
  props: {},
  data() {
    return {
      ...api.command.getState()
    }
  },
  computed: {},
  mounted() {},
  methods: {
    sumbit() {
      this.$emit('success')
    },
    copyPushUrl(idClass) {
      copyUrl(idClass)
      this.$message.info('复制链接成功！')
    },
    pushUrl() {
      return (
        <div>
          <p>
            推流地址在第三方推流应用中，以下地址进行推流，
            <a onClick={() => this.copyPushUrl('p1')}>复制链接</a>
          </p>
          <p id="p1">
            rtmp://wxalivepush.weixin.qq.comlivewx7cc82a20131a0be4-107xSecret=77e3770a29c0c78bfb93c2f7c083b77c&txTime=611bdd0
          </p>
          <hr style="margin: 50px 0 25px 0" />
          <p>此地址为当前直播间唯一推流地址，不要泄漏给第三方。</p>
          <p>
            服务器地址：<a onClick={() => this.copyPushUrl('p2')}>复制链接</a>
          </p>
          <p id="p2">rtmp://wxalivepush.weixin.qq.com/live/</p>
          <p>
            串 流 密 钥：<a onClick={() => this.copyPushUrl('p3')}>复制链接</a>
          </p>
          <p id="p3">wx7cc82a20131a0be4-107txSecret=77e3770a29c0c78bfb93c2f7c083b77c&txTime=611bdd00</p>
        </div>
      )
    }
  },
  render() {
    return <div>{this.pushUrl()}</div>
  }
}
</script>
