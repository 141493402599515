<script>
import G2 from '@antv/g2'
export default {
  props: {
    charData: {
      type: Array,
      default: function() {
        return {
          data: []
        }
      }
    },
    id: String
  },
  data() {
    return {
      chart: null
    }
  },
  // 如果使用serverData传过来的静态数据 请使用mounted()方法 并注释掉watch
  mounted() {
    this.drawChart()
  },
  // 监听API接口传过来的数据 使用watch
  // watch: {
  // charData: function (val, oldVal) {    // 监听charData，当发生变化时，触发这个回调函数绘制图表
  // console.log('new: %s, old: %s', val, oldVal);
  // this.drawChart(val);
  // }
  methods: {
    drawChart() {
      // 2019.03.30 更新 destory方法已被废弃
      // this.chart && this.chart.destory()
      this.chart = new G2.Chart({
        container: this.id,
        forceFit: true,
        height: 400
      })
      this.chart.source(this.charData)
      this.chart.scale('value', {
        min: 0
      })
      this.chart.scale('year', {
        range: [0, 1]
      })
      this.chart.tooltip({
        crosshairs: {
          type: 'line',
          fill: '#000'
        }
      })
      this.chart.line().position('year*value')
      this.chart
        .point()
        .position('year*value')
        .size(4)
        .shape('circle')
        .style({
          stroke: '#fff',
          lineWidth: 1
        })
      this.chart.render()
    }
  },
  render() {
    return <div id={this.id} class="gcharts"></div>
  }
}
</script>
<style lang="less" scope>
.gcharts {
  width: 100%;
  height: 100%;
}
</style>
